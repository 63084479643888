import React from 'react'
import { GlobalPadding } from './Wrappers'
import { color, breakpoint, transition } from '@configs/utilities'
import styled from 'styled-components'

const ServicesWrapper = ({ children }) => {
  return (
    <Wrapper>
      <GlobalPadding>{children}</GlobalPadding>
    </Wrapper>
  )
}

export default ServicesWrapper

const Wrapper = styled.div`
  font-weight: 300;
  line-height: 24px;
  color: ${color.font};
  font-size: 13px;
  padding: 50px 0;
  @media screen and (min-width: ${breakpoint.lg}) {
    font-size: 15px;
  }
  h1 {
    font-size: 30px;
    font-weight: 500;
    margin: 20px 0;

    @media screen and (min-width: ${breakpoint.lg}) {
      font-size: 36px;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 32px 0 20px;

    @media screen and (min-width: ${breakpoint.lg}) {
      font-size: 28px;
      margin: 48px 0 20px;
    }
  }

  a {
    color: ${color.font};
    transition: ${transition.default};

    :hover {
      color: ${color.blue};
    }
  }
  .spanLink {
    color: ${color.blue};
    transition: ${transition.default};
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }
  }

  ol {
    li {
      margin-left: 30px;
    }
  }

  ul {
    li {
      margin-left: 30px;
    }
  }

  .circle {
    li {
      list-style-type: circle;
    }
  }

  .leftElement {
    margin: 0 3px;
  }

  .inkInside {
    margin: 0 5px;
  }

  .underline {
    text-decoration: underline;
  }

  .sectionLeft {
    padding-left: 20px;
    p,
    ul {
      padding-left: 20px;
    }
  }
  .address {
    margin-top: 20px;
  }
  .marginVertical {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .marginTop {
    margin-top: 20px;
  }
  .marginBottom {
    margin-bottom: 20px;
  }
`
